import React, { useEffect, useState } from 'react'

// GraphQL
import { UPDATE_REPRINT_REQUEST } from '../../graphql/mutations'

// Plugins
import PubSub from 'pubsub-js'
import { Form, Button, message, Select, Checkbox } from 'antd'

// Components
import Drawer from '../shared/Drawer'

// Helpers / Hooks
import { useMutation } from '@apollo/react-hooks'
import { useGraphqlError, useErrors } from '../../utilities/hooks'

// Constants
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}
const tailLayout = {
  wrapperCol: { offset: 6, span: 18 }
}

const ApproveReprintDrawer = (props) => {
  const { request, onClose } = props
  const [form] = Form.useForm()
  const [shippingRatesList, setShippingRatesList] = useState([])

  const order = request.orderReprint?.order
  const { shippingOptions: items } = request.orderReprint?.order

  const [updateReprintRequest, { data, loading, error }] = useMutation(UPDATE_REPRINT_REQUEST)
  useErrors(data?.updateReprintRequest?.errors)
  useGraphqlError(error)

  const onFinish = async (values) => {
    const colorCorrection = values.colorCorrection ? true : false
    const params = {
      variables: {
        input: {
          id: request.id,
          status: 'approved',
          comment: null,
          shippingRateId: values.shippingRateId,
          colorCorrection: colorCorrection
        }
      }
    }
    const response = await updateReprintRequest(params)
    const errors = response.data?.updateReprintRequest?.errors

    if (errors && errors.length > 0) return
    message.success('Support request updated.')
    PubSub.publish('PAGED_LIST_REFRESH')
    onClose()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    let itemsFiltered = items.filter((shippingRateItem) => !shippingRateItem?.bulk)
    if (itemsFiltered.length > 0) {
      setShippingRatesList(itemsFiltered)
      form.setFieldsValue({
        shippingRateId: itemsFiltered[0].id
      })
    }
  }, [items, setShippingRatesList, request, form])

  return (
    <Drawer {...props} width={600}>
      <h3>Approve request for order {order.num}</h3>
      <Form form={form} {...layout} style={{ padding: 20 }} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item label="Shipping Rate" name={'shippingRateId'}>
          <Select>
            {shippingRatesList.map((rate, index) => {
              return (
                <Select.Option key={index} value={rate.id}>
                  {rate.description} ({rate.rate})
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {order && order.lab.key === 'whcc' && (
          <Form.Item name="colorCorrection" valuePropName="checked" label="Color Correction">
            <Checkbox />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Approve
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default ApproveReprintDrawer
