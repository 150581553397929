import { gql } from 'graphql.macro'
import {
  ADDRESS_ATTRIBUTES,
  CUSTOMER_ATTRIBUTES,
  CUSTOMER_SHORT_ATTRIBUTES,
  GIFT_CARD_ATTRIBUTES,
  JOB_ATTRIBUTES,
  JOB_SHORT_ATTRIBUTES,
  LAB_ATTRIBUTES,
  LAB_SHORT_ATTRIBUTES,
  ORDER_ATTRIBUTES,
  REFUND_GROUP_ATTRIBUTES,
  STUDIO_ATTRIBUTES,
  STUDIO_SHORT_ATTRIBUTES,
  USER_ATTRIBUTES,
  SUPPORT_REQUEST_REPRINT_ATTRIBUTES,
  SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES,
  SUPPORT_REQUEST_EVENT_ATTRIBUTES
} from './fragments'

export const ADMINISTRATORS_QUERY = gql`
  query AdministratorsQuery($id: ID!) {
    user(id: $id) {
      ...userAttributes
    }
  }
  ${USER_ATTRIBUTES}
`

export const CUSTOMER_QUERY = gql`
  query CustomerQuery($id: ID!) {
    customer(id: $id) {
      ...customerAttributes
      giftCards {
        ...giftCardAttributes
      }
    }
  }
  ${CUSTOMER_ATTRIBUTES}
  ${GIFT_CARD_ATTRIBUTES}
`

export const CUSTOMER_SMS_CODE_QUERY = gql`
  query CustomerSmsCodeQuery($id: ID!) {
    customer(id: $id) {
      smsCode
    }
  }
`

export const CUSTOMERS_LIST_QUERY = gql`
  query CustomersListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    customers(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...customerAttributes
      }
    }
  }
  ${CUSTOMER_ATTRIBUTES}
`

export const DASHBOARD_GIFT_CARD_LIST_QUERY = gql`
  query GiftCardListCardQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    giftCards(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      items {
        ...giftCardAttributes
      }
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
`

export const DASHBOARD_ORDERS_LIST_QUERY = gql`
  query OrderListCardQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    orders(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      items {
        id
        num
        studio {
          id
          name
          logoUrl
        }
        total
      }
    }
  }
`

export const GIFT_CARD_QUERY = gql`
  query GiftCardQuery($id: ID!) {
    giftCard(id: $id) {
      ...giftCardAttributes
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
`

export const GIFT_CARD_BY_NUM_QUERY = gql`
  query GiftCardQuery($num: String!) {
    giftCard(num: $num) {
      ...giftCardAttributes
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
`

export const GIFT_CARDS_LIST_QUERY = gql`
  query GiftCardsListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    giftCards(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...giftCardAttributes
      }
    }
  }
  ${GIFT_CARD_ATTRIBUTES}
`

export const JOB_QUERY = gql`
  query JobQuery($id: ID!) {
    job(id: $id) {
      ...jobAttributes
      purchasesSummary {
        viewCount
        orderCount
        giftCardCount
        orderSales
        creditSales
        usedCredits
        unusedCredits
        totalSales
        studioPayouts
        avgOrderValue
      }
    }
  }
  ${JOB_ATTRIBUTES}
`

export const JOBS_LIST_QUERY = gql`
  query JobListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    jobs(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...jobAttributes
      }
    }
  }
  ${JOB_ATTRIBUTES}
`

export const LABS_LIST_QUERY = gql`
  query LabsListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    labs(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...labAttributes
      }
    }
  }
  ${LAB_ATTRIBUTES}
`

export const ORDER_QUERY = gql`
  query OrderQuery($num: String) {
    order(num: $num) {
      ...orderAttributes
    }
  }
  ${ORDER_ATTRIBUTES}
`

export const POLICY_QUERY = gql`
  query CompanyPolicyQuery($id: ID!) {
    companyPolicy(id: $id) {
      id
      name
      description
      key
      url
      userChangedAt
      customerChangedAt
    }
  }
`

export const ORDERS_LIST_QUERY = gql`
  query OrderListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    orders(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        id
        num
        phone
        email
        totalCents
        purchasedAt
        sentAt
        sentOrdersCount
        refundable
        payoutId
        shippingRateId
        status
        shippingOptions {
          id
          description
        }
        address {
          ...addressAttributes
        }
        studio {
          ...studioShortAttributes
        }
        customer {
          ...customerShortAttributes
        }
        job {
          ...jobShortAttributes
        }
        lab {
          ...labShortAttributes
        }
        bulkShipOrderId
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
  ${STUDIO_SHORT_ATTRIBUTES}
  ${CUSTOMER_SHORT_ATTRIBUTES}
  ${JOB_SHORT_ATTRIBUTES}
  ${LAB_SHORT_ATTRIBUTES}
`

export const POLICIES_LIST_QUERY = gql`
  query CompanyPoliciesListQuery($page: Int, $perPage: Int, $order: String, $dir: String) {
    companyPolicies(page: $page, perPage: $perPage, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        id
        name
        description
        key
        url
        userChangedAt
        customerChangedAt
      }
    }
  }
`

export const ORDER_SENT_ORDERS_QUERY = gql`
  query OrderSentOrdersQuery($num: String) {
    order(num: $num) {
      id
      num
      lab {
        ...labShortAttributes
      }
      sentOrders {
        id
        sentToPrinter
        createdAt
      }
    }
  }
  ${LAB_SHORT_ATTRIBUTES}
`

export const REFUND_GROUP_QUERY = gql`
  query RefundGroupQuery($id: ID!) {
    refundGroup(id: $id) {
      ...refundGroupAttributes
    }
  }
  ${REFUND_GROUP_ATTRIBUTES}
`

export const REFUND_GROUPS_LIST_QUERY = gql`
  query RefundGroupListQuery(
    $purchasableId: ID
    $purchasableType: String
    $page: Int
    $perPage: Int
    $search: String
    $searchField: String
    $order: String
    $dir: String
  ) {
    refundGroups(
      purchasableId: $purchasableId
      purchasableType: $purchasableType
      page: $page
      perPage: $perPage
      search: $search
      searchField: $searchField
      order: $order
      dir: $dir
    ) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...refundGroupAttributes
      }
    }
  }
  ${REFUND_GROUP_ATTRIBUTES}
`

export const REFUNDS_MAXIMUM_QUERY = gql`
  query RefundMaximumsQuery($payoutId: ID!) {
    refundMaximums(payoutId: $payoutId) {
      maximumLabCents
      maximumStudioCents
      maximumCustomerCents
    }
  }
`

export const SHIPPING_RATES_LIST_QUERY = gql`
  query ShippingRatesListQuery($perPage: Int, $labId: ID!) {
    shippingRates(perPage: $perPage, labId: $labId) {
      items {
        id
        rate
        rateCents
        description
        minimumAmountCents
        maximumAmountCents
      }
    }
  }
`

export const PRICE_SHEET_LIST_QUERY = gql`
  query PriceSheetsListQuery($perPage: Int, $order: String, $studioId: ID!) {
    priceSheets(perPage: $perPage, order: $order, studioId: $studioId) {
      items {
        id
        name
      }
    }
  }
`

export const STUDIO_SHORT_LIST_QUERY = gql`
  query StudiosListQuery($perPage: Int, $order: String) {
    studios(perPage: $perPage, order: $order) {
      items {
        ...studioShortAttributes
      }
    }
  }
  ${STUDIO_SHORT_ATTRIBUTES}
`

export const STUDIO_QUERY = gql`
  query StudioQuery($id: ID!) {
    studio(id: $id) {
      ...studioAttributes
    }
  }
  ${STUDIO_ATTRIBUTES}
`

export const STUDIO_LIST_QUERY = gql`
  query StudiosListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String, $withSupportRequests: Boolean) {
    studios(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir, withSupportRequests: $withSupportRequests) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...studioAttributes
      }
    }
  }
  ${STUDIO_ATTRIBUTES}
`

export const STUDIO_SLIM_LIST_QUERY = gql`
  query StudiosListQuery($page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String, $withSupportRequests: Boolean) {
    studios(page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir, withSupportRequests: $withSupportRequests) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        id
        name
      }
    }
  }
`

export const SUPPORT_REQUEST_QUERY = gql`
  query SupportRequestQuery($id: ID!) {
    supportRequest(id: $id) {
      ...supportRequestRerintAttributes
      ...supportRequestSmsCampaignAttributes
    }
  }
  ${SUPPORT_REQUEST_REPRINT_ATTRIBUTES}
  ${SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES}
`

export const SUPPORT_REQUEST_LIST_QUERY = gql`
  query SupportRequestsListQuery(
    $page: Int
    $perPage: Int
    $search: String
    $searchField: String
    $order: String
    $dir: String
    $requestType: String
    $status: String
    $lab: String
    $studio: String
    $dateFrom: String
    $dateTo: String
  ) {
    supportRequests(
      page: $page
      perPage: $perPage
      search: $search
      searchField: $searchField
      order: $order
      dir: $dir
      requestType: $requestType
      status: $status
      lab: $lab
      studio: $studio
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...supportRequestRerintAttributes
        ...supportRequestSmsCampaignAttributes
      }
    }
  }
  ${SUPPORT_REQUEST_REPRINT_ATTRIBUTES}
  ${SUPPORT_REQUEST_SMS_CAMPAIGN_ATTRIBUTES}
`

export const SUPPORT_REQUEST_EVENT_LIST_QUERY = gql`
  query SupportRequestEventsListQuery(
    $page: Int
    $perPage: Int
    $search: String
    $searchField: String
    $order: String
    $dir: String
    $eventType: String!
    $supportRequestId: String!
  ) {
    supportRequestEvents(
      page: $page
      perPage: $perPage
      search: $search
      searchField: $searchField
      order: $order
      dir: $dir
      eventType: $eventType
      supportRequestId: $supportRequestId
    ) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...supportRequestEventAttributes
      }
    }
  }
  ${SUPPORT_REQUEST_EVENT_ATTRIBUTES}
`

export const USER_QUERY = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      ...userAttributes
    }
  }
  ${USER_ATTRIBUTES}
`

export const USERS_LIST_QUERY = gql`
  query UsersListQuery($role: String, $page: Int, $perPage: Int, $search: String, $searchField: String, $order: String, $dir: String) {
    users(role: $role, page: $page, perPage: $perPage, search: $search, searchField: $searchField, order: $order, dir: $dir) {
      pageInfo {
        page
        perPage
        totalCount
        totalPages
        nextPage
        previousPage
      }
      items {
        ...userAttributes
      }
    }
  }
  ${USER_ATTRIBUTES}
`
